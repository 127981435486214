/* eslint-disable no-console, no-undef */

// single-spa import below only works if we explicitly import it on the index file with systemJs
import { registerApplication, start } from 'single-spa';
import { Authentication } from '@sdi-one-core/utilities-event-installations-app-web';

// Okta config
const oktaConfig = Authentication.getOktaConfig();
const subAppOktaConfig = {
  ...oktaConfig,
  tokenManager: {
    autoRenew: false, // stop auto renew for all apps except Authentication (responsible for updating tokens)
  },
};

/**
 *  Registering Single-spa apps
 */

// Authentication (with Okta)
registerApplication({
  name: '@sdi-one-core/authentication-event-installations-app-web',
  app: () =>
    System.import('@sdi-one-core/authentication-event-installations-app-web'),
  activeWhen: (location) => true,
  customProps: { oktaConfig: oktaConfig },
});

// Navigation
registerApplication({
  name: '@sdi-one-core/navigation-event-installations-app-web',
  app: () =>
    System.import('@sdi-one-core/navigation-event-installations-app-web'),
  activeWhen: (location) => !location.pathname.startsWith('/implicit/callback'), // this is set in Okta.com
  customProps: { oktaConfig: subAppOktaConfig },
});

// Connected devices
registerApplication({
  name: '@sdi-one-core/event-connected-devices-console-app-web',
  app: () =>
    System.import('@sdi-one-core/event-connected-devices-console-app-web'),
  activeWhen: (location) => location.pathname.startsWith('/devices'),
  customProps: {
    oktaConfig: subAppOktaConfig,
  },
});

// Clients
registerApplication({
  name: '@sdi-one-core/event-clients-console-app-web',
  app: () => System.import('@sdi-one-core/event-clients-console-app-web'),
  activeWhen: (location) => location.pathname.startsWith('/clients'),
  customProps: {
    oktaConfig: subAppOktaConfig,
  },
});

// Locations
registerApplication({
  name: '@sdi-one-core/event-locations-console-app-web',
  app: () => System.import('@sdi-one-core/event-locations-console-app-web'),
  activeWhen: (location) => location.pathname.startsWith('/locations'),
  customProps: {
    oktaConfig: subAppOktaConfig,
  },
});

// Networks
registerApplication({
  name: '@sdi-one-core/event-networks-console-app-web',
  app: () => System.import('@sdi-one-core/event-networks-console-app-web'),
  activeWhen: (location) => location.pathname.startsWith('/networks'),
  customProps: {
    oktaConfig: subAppOktaConfig,
  },
});

// Events (root)
registerApplication({
  name: '@sdi-one-core/event-events-console-app-web',
  app: () => System.import('@sdi-one-core/event-events-console-app-web'),
  activeWhen: (location) => location.pathname.startsWith('/'),
  customProps: {
    oktaConfig: subAppOktaConfig,
  },
});

// Error placeholder
registerApplication({
  name: '@error',
  app: () =>
    new Promise(() => {
      return true;
    }),
  activeWhen: (location) => location.pathname.startsWith('/error'),
});

// Start Single-SPA app
start();
